import React, { useEffect } from 'react'
import { graphql, Link } from "gatsby"
import { SliceZone } from "@prismicio/react"
import { components } from '../../slices'
import Layout from '../../components/layout';
import { Container } from 'react-bootstrap';
import { StaticImage } from 'gatsby-plugin-image';
import Cursor from '../../components/Cursor';
import AOS from 'aos';
import 'aos/dist/aos.css';

export default function Article({ data }) {

  useEffect(() => {
    AOS.init({
      offset: -10,
      delay: 50,
      duration: 800,
      easing: 'ease',
      mirror: true
    });
  }, [])

  const article = data.prismicNewsPress.data

  // allArticleUids => an array with all article uid for previous / next buttons.
  // articles are ORDERED BY date
  const allArticleUids = data.allPrismicNewsPress.nodes.map(article => article.uid)
  const indexOfUid = allArticleUids.indexOf(data.prismicNewsPress.uid)
  // prevLink: if you are at the first project go to the last one, else, the previus one.
  let prevLink = "/articles/";
  indexOfUid === 0 ? prevLink += allArticleUids.pop() : prevLink += allArticleUids[indexOfUid - 1]
  // nextLink: if you are at the last project go to the first one, else, at the next one.
  let nextLink = "/articles/";
  indexOfUid === allArticleUids.length - 1 ? nextLink += allArticleUids[0] : nextLink += allArticleUids[indexOfUid + 1]
  return (
    <Layout>
      <Container fluid className='article-page pt-5'>
        <div className='article-header mt-4 d-flex justify-content-between mt-5' data-aos="fade-up">
          <h3>{article.date}</h3>
          <h3 className='allArticlesLink'><Link to="/articles">ALL<Cursor /></Link></h3>
        </div>
        <h1 className='my-4' data-aos="fade-up">{article.title}</h1>
        <div className='article-content'>
          <SliceZone slices={data.prismicNewsPress.data.body} components={components} data-aos="fade-up" />
        </div>
        <div className="pagination d-flex justify-content-center mt-3 px-3">
          <Link to={prevLink} className="pag-prev p-3 bg-light border-0">
            <StaticImage
              src='../../images/shapes/leftArrow.png'
              alt="previous arrow"
            />
          </Link>
          <Link to={nextLink} className="pag-next p-3 bg-light border-0">
            <StaticImage
              src='../../images/shapes/Group 2.svg'
              alt="next arrow"
            />
          </Link>
        </div>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query ArticleQuery($id: String){
    allPrismicNewsPress(sort: {order: DESC, fields: data___date}) {
      nodes {
        uid
      }
    }
    prismicNewsPress(id: {eq: $id}) {
        id
        data {
        category
        date
        title
        body {
            ... on PrismicNewsPressDataBodyEmbedVideo {
            id
            slice_type
            primary {
                video_title
                video_subtitle
                video_url {
                html
                embed_url
                }
            }
            }
            ... on PrismicNewsPressDataBodyRichText {
            id
            slice_type
            primary {
                main_content {
                richText
                }
            }
            }
        }
        }
        uid
        tags
    }
  }
`